import runnersGif from '../assets/image/runners.gif';
// import pibbie from '../assets/image/pibbie.png';
// import { checkEmail } from './email';

import './Thankyou.scss';

function Thankyou() {
  // const [email, setEmail] = useState('');

  // const onSubmit = () => {
  //   axios
  //     .post('/api/emails', { email })
  //     .then(res => {
  //       if (window.fbq !== null) window.fbq('track', 'Email Submitted');
  //     })
  //     .catch(err => { })
  //     .then(() => { });
  // };

  // const onEmailInputChange = (e) => {
  //   setSubmitBtnDisabled(true);

  //   const newEmail = e.target.value;
  //   setEmail(newEmail);

  //   if (checkEmail(newEmail)) return setSubmitBtnDisabled(false);
  // }

  return (
    <div className="thankyou">
      <div className="mt-4 row justify-content-around p-0 m-0">
        <div className="p-0 m-0">
          <img className="hero img-fluid" src={runnersGif} alt="Thank you" />
          <div className="container">

            <div className="row justify-content-lg-center">
              <div>
                <h2 className="font-weight-bold text-center mt-3">Review submitted.</h2>
                <h2 className="font-weight-bold text-center">Thank you for not leaving</h2>
                <h2 className="font-weight-bold text-center">anyone behind</h2>
                <p className="text-center">
                  Your contribution help others make<br></br>
                  well-informed decision.
                </p>
                {/* <div className="power-by mt-3">
                  <div className="me-1">Powered by</div>
                  <img className="img-fluid" src={pibbie} alt="Pibbie" />
                </div> */}
                {
                  // emailSent ?
                  //   <div className="submitted mt-5">
                  //     <EmailLogo/><span className="ms-2">Email submitted</span>
                  //   </div>
                  //   :
                  //   <>
                  //     <div className="text-center">
                  //       <input type="email" className="w-75 text-center mt-4 is-invalid"
                  //         value={email}
                  //         placeholder="Provide an email to see it first"
                  //         onChange={onEmailInputChange}
                  //       />
                  //     </div>
                  //     <div className="text-center mt-3">
                  //       <button type="button" className="btn btn-secondary px-4"
                  //         onClick={onSubmit} disabled={submitBtnDisabled}>
                  //         Submit
                  //       </button>
                  //     </div>
                  //   </>
                }

                {
                  // TODO: uncomment for FB login to post
                  <div className="text-center mt-5">
                    <a href="/" className="btn btn-secondary px-5 py-2">
                      Return to Result
                    </a>
                  </div>
                }

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;

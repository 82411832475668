function WithContext(WrappedComponent) {
  return (props) => {
    return (
      <WrappedComponent />
    );
  };
}

export default WithContext;



import './Footer.scss';

function Footer() {
  return (
    <footer className="container">
      <div className="text-center py-3" style={{ fontSize: '0.8em', color: '#6c757d' }}>
        Copyright &copy; 2024 Pibbie
      </div>
    </footer>
  )
}
export default Footer;

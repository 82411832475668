import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { ReactComponent as PhotoLogo } from '../assets/icon/photo.svg';
import './ConfirmModal.scss';

const FILE_SIZE_LIMIT = 1024 * 1024 * 10; //10 MB

function ConfirmModal(props) {
  let {
    show,
    onClose = () => {},
    onConfirm = () => {},
  } = props;
  const [uploadText, setUploadText] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [comment, setComment] = useState();
  const [formData, setFormData] = useState(undefined);

  const onFileInputChange = (e) => {
    setUploadError(false);
    setUploadText('');

    let uploadFiles = 0;
    if (e.target.files.length === 0) return;

    const fd = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setUploadError(true);
        continue;
      }
      fd.append('files', file, file.name);
      uploadFiles++;
    }

    if (uploadFiles > 0 ) {
      setUploadText(`${uploadFiles} file(s) will be uploaded`);
      setFormData(fd);
      return;
    }

    setUploadText('');
  }

  return (
    <Modal className="modal" show={show} onHide={onClose} centered>
      {
        // TODO: Fackbook login to post
        // <Modal.Body className="text-center mt-4 mb-3">
        //   <h4>We use Facebook to validate single submission per user</h4>
        //   <div className="mt-3"/>
        //   <div className="mt-4">
        //     <LoginWithFB onConfirm={onConfirm}/>
        //   </div>
        //   <div className="mt-2">
        //     <Button variant="secondary" onClick={onClose}>
        //       Cancel
        //     </Button>
        //   </div>
        // </Modal.Body>
      }
      {
        <>
          <Modal.Body className="text-center mt-2 mx-2">
            <h4 className="mb-3">Confirm</h4>
            <textarea className="form-control p-3 mb-2"
              id="exampleFormControlTextarea1"
              placeholder='Share your experience&#013;&#013;-- name'
              onChange={e => setComment(e.target.value)}
              rows="3">
            </textarea>
            { /*
              <input type="text" className="form-control p-3 mb-3"
                placeholder={'Name (optional)'}
                onChange={e => setName(e.target.value)}
              />
              */
            }
            <input type="file" name="file" id="file" className="inputfile"
              accept="image/png,image/jpeg,video/*" onChange={onFileInputChange}
              multiple
            />
            <div className="text-start">
              <label htmlFor="file">
                <div className="text-start">
                  <PhotoLogo/>
                  <small className="ms-2">{uploadText}</small>
                </div>
              </label>
            </div>
            {
              uploadError &&
                <div className="error mt-2">
                  Some file(s) cannot be uploaded due to upload size limit of 10MB.
                </div>
            }
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onConfirm({
              uploadFormData: formData,
              comment,
            })}>
              Submit
            </Button>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
}
export default ConfirmModal;

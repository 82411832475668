import classNames from 'classnames';
import { ReactComponent as LoadingIcon } from '../assets/icon/loading.svg';

import './Loading.scss'
function Loading(props) {
  const { show = false } = props;

  return (
    <div className={classNames('loading', {'d-none': !show})}>
      <div className="d-flex h-100">
        <LoadingIcon/>
      </div>
    </div>
  );
}

export default Loading;

const NAME_PATTERN = /\n\n(.+)/;


const parseComment = (comment) => {
  if (!comment) return { name: '', comment: '' };
  let name = comment.match(NAME_PATTERN);
  name = name && name[1];
  name = name?.replaceAll('-', '')?.trim();

  return {
    name,
    comment: comment.replaceAll(/\n\n(.+)/g, ''),
  };
}

export default parseComment

import React, { useState, useMemo, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import dayjs from 'dayjs';
import classNames from 'classnames';
import emoji from './emoji';
import './Shoe.scss';
import shoeImg from '../assets/image/shoe.png';
import { ReactComponent as LikeIcon } from './../assets/icon/like.svg';
import { ReactComponent as PhotoLogo } from '../assets/icon/photo.svg';
import { Button } from 'react-bootstrap';

import {
  badEmo,
  loveEmo,
  likeEmo,
  happyEmo,
  worseEmo,
  badGrayEmo,
  loveGrayEmo,
  likeGrayEmo,
  happyGrayEmo,
  worseGrayEmo,
} from '../assets/emoji';
import MileageModal from './MileageModal';
import ConfirmModal from './ConfirmModal';
import commentName from './commentName';

const TIMEZONE = 'Asia/Bangkok';
const FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const FILE_SIZE_LIMIT = 1024 * 1024 * 10; //10 MB


const InitShoeData = {
  rank: '',
  brand: '',
  image_url: shoeImg,
  mileage: '',
  avg_mileage: '',
  model: '',
  price: '',
  shoe_offset: '',
  total_votes: '',
  type: '',
  weight: '',
  attributes: [],
  rankPercents: [0, 0, 0],
};

const Info = React.memo(({ data }) => {
  const costInfo = (info) => {
    const { price: p, avg_mileage: m } = info;
    const price = parseFloat(p);
    let avg_mileage = parseFloat(m);
    if (isNaN(price)) return;
    if (isNaN(avg_mileage)) avg_mileage = 0;
    let content = [];
    content.push(<li key="price">{`$ ${price}`}</li>);

    return <>{content}</>;
  }

  const weightInfo = (info) => {
    const { weight: w } = info;
    const weight = parseFloat(w);
    if (isNaN(weight)) return;
    const ozToG = 28.3495;
    const result = Math.round(weight * ozToG * 100) / 100;

    return <li>{weight} oz | {result}g</li>;
  };

  return (
    <>
      <div className="row justify-content-around mt-0 mb-4">
        <div className="row">
          <div className="col-6 px-0 pr-2">
            <img src={data.image_url || shoeImg} className="img-fluid"
              alt={`${data.brand} ${data.model}`} />
          </div>

          <div className="col-6">
            <div className="h-100 d-flex flex-column justify-content-center align-content-start">
              <div className="h4 font-weight-bold">
                {data.brand} {data.model}
              </div>
              <ul className="mt-2">
                <li>
                  <div className='bg-black text-white px-1' style={{ width: 'fit-content' }}>
                    Overall Rank: {data.rank} 
                  </div>
                </li>
                <li>{data.total_votes || 'N/A'} Reviews</li>
                {costInfo(data)}
                {weightInfo(data)}
                {data.shoe_offset && (
                  <li>Offset: {data.shoe_offset}mm</li>
                )}
              </ul>
            </div>
          </div> 

        </div>
      </div>
    </>
  );
});

const Emoji = React.memo(({ emojis, seleted, onClick }) => {
  return (
    <>
      <div className="fw-bold">
        <div className="mb-4 emojis">
          {
            emojis.map((v, i) => <div key={i} className="px-1">{v}</div>)
          }
        </div>
        <div className="mb-4 emos">
          <img src={seleted === '💩' ? worseEmo : worseGrayEmo} alt="worse emo" onClick={() => onClick('💩')} />
          <img src={seleted === '👎' ? badEmo : badGrayEmo} alt="bad emo" onClick={() => onClick('👎')} />
          <img src={seleted === '😐' ? happyEmo : happyGrayEmo} alt="happy emo" onClick={() => onClick('😐')} />
          <img src={seleted === '👍' ? likeEmo : likeGrayEmo} alt="like emo" onClick={() => onClick('👍')} />
          <img src={seleted === '❤️' ? loveEmo : loveGrayEmo} alt="love emo" onClick={() => onClick('❤️')} />
        </div>
      </div>
    </>
  );
});

const Rank = React.memo(({ data }) => {
  return (
    <>
      <div className="rank-container">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <div>Best choice</div>
          <div><span className='h3 fw-bold'>{data.rankPercents.rankFirst}</span>%</div>
        </div>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <div>Top 3 choices</div>
          <div><span className='h3 fw-bold'>{data.rankPercents.topThreeAvg}</span>%</div>
        </div>
      </div>
    </>
  );
});

const Vote = React.memo(({ data, onLike, onUnvote }) => {
  return (
    <>
      <div>
        {
            data.attributes.map((att, i) => (
              <div key={i} className="d-flex justify-content-between align-items-center mb-3">
                <button className="attribute">{att.text}</button>
                <div className="d-flex align-items-center">
                  <div className="me-3">{att.votes === '0' ? '' : '+'}{att.votes}</div>
                  <div className={classNames('like-btn', { 'voted': att.voted })}
                    onClick={e => {
                      if (!att.voted) return onLike(att);
                      onUnvote(att);
                    }
                    }>
                    <LikeIcon />
                  </div>
                </div>
              </div>
            ))
        }
      </div>
    </>
  );
});

const Review = React.memo((props) => {
  const { data, k } = props;
  const { name, comment, created_at } = data;

  return (
    <div className="pb-3">
      <div>
        <div className="d-flex justify-content-between">
          <div className="fw-bold"><span>{emoji[k]}&nbsp;</span>{name}</div>
          <div>{dayjs(created_at).tz('Asia/Bangkok').format('DD/MM/YY')}</div>
        </div>
        <div className="fst-italic mt-1">{comment}</div>
      </div>
    </div>
  )
});

const  Reviews = React.memo(({ reviews }) => {
  return (
    <div className="reviews mb-3">
      <div className="container">
        {reviews.map((v, i) => (
          <Review key={i} k={i % emoji.length} data={v} />
        ))}
      </div>
    </div>
  );
});

const InputReview = ({ onConfirm }) => {
  const [comment, setComment] = useState();
  const [uploadText, setUploadText] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [formData, setFormData] = useState(undefined);

  const onFileInputChange = (e) => {
    setUploadError(false);
    setUploadText('');

    let uploadFiles = 0;
    if (e.target.files.length === 0) return;

    const fd = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      if (file.size > FILE_SIZE_LIMIT) {
        setUploadError(true);
        continue;
      }
      fd.append('files', file, file.name);
      uploadFiles++;
    }

    if (uploadFiles > 0 ) {
      setUploadText(`${uploadFiles} file(s) will be uploaded`);
      setFormData(fd);
      return;
    }

    setUploadText('');
  }

  const onClickConfirm = () => {
    onConfirm({ uploadFormData: formData, comment });
    setComment(null);
    document.getElementById('input-text-share-experience').value = '';
  }

  return (
    <div>
      <textarea className="mb-4 p-3 form-control"
        id="input-text-share-experience"
        placeholder='Share your experience&#013;&#013;-- name'
        onChange={e => setComment(e.target.value)}
        rows="3">
      </textarea>
      <input type="file" name="file" id="file" className="inputfile"
        accept="image/png,image/jpeg,video/*" onChange={onFileInputChange} multiple />
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <label htmlFor="file">
          <div className="text-start">
            <PhotoLogo/>
            <small className="ms-2">{uploadText}</small>
          </div>
        </label>
        <Button style={{ borderRadius: '1.25rem' }} variant="primary" onClick={() =>  onClickConfirm()}>
          Submit
        </Button>
      </div>
      {
        uploadError &&
          <div className="error">
            Some file(s) cannot be uploaded due to upload size limit of 10MB.
          </div>
      }
    </div>
  );
}

const Shoe = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(true);
  const [shoe, setShoe] = useState(InitShoeData);
  const [emojis, setEmojis] = useState([]);
  const [seletedEmoji, setSeletedEmoji] = useState(null);
  const [canVote, setCanVote] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [landedAt] = useState(dayjs().tz(TIMEZONE).format(FORMAT));
  const [reviews, setReviews] = useState([]);


  const { id } = useParams();

  const memoizedShoe = useMemo(() => {
    return shoe;
  }, [shoe]);


  useEffect(() => {
    fetchReviews(id)
  }, [id]);

  const fetchReviews = (id) => {
    axios.get(`/api/feedbacks/product/${id}`)
      .then(result => {
        const { data } = result.data
        setReviews(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    Promise.all([
      axios.get(`/api/shoes/${id}`)
        .then(res => {
          setShoe(res.data);
        }),
      axios.get(`/api/shoes/${id}/emojis`)
        .then(res => {
          setEmojis(res.data.emojis);
        }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [id]);

  const fetchShoe = () => {
    axios.get(`/api/shoes/${id}`)
      .then(res => {
        setShoe(res.data);
      })
      .catch(err => { });
  }

  const fetchEmojis = () => {
    axios.get(`/api/shoes/${id}/emojis`)
      .then(res => {
        setEmojis(res.data.emojis);
      })
      .catch(err => console.error(err));
  };

  const onEmojiClick = (emoji) => {
    setSeletedEmoji(seletedEmoji === emoji ? null : emoji);
  };
  
  const onLike = (att) => {
    if (!canVote) return;

    setCanVote(false);
    axios.post(`/api/attributes/${att.id}/vote`)
      .then(res => {
        fetchShoe();
      })
      .catch(err => { })
      .finally(() => {
        setCanVote(true);
      });
  };

  const onUnvote = (att) => {
    if (!canVote) return;

    setCanVote(false);
    axios.delete(`/api/attributes/${att.id}/vote`)
      .then(res => {
        fetchShoe();
      })
      .catch(err => { })
      .finally(() => {
        setCanVote(true);
      });
  }

  const onMileageSubmit = (mile) => {
    axios.post('/api/mileage', { productId: id, mile })
      .catch(err => { })
      .finally(() => {
        setShowModal(false);
        fetchShoe();
      });
  };

  const onConfirmClick = async ({ uploadFormData, comment, fbUserID, fbEmail}) => {
    let images;
    try {
        if (uploadFormData) {
            const uploadResponse = await axios.post('/api/uploads', uploadFormData, {
                headers: {'Content-Type': 'multipart/form-data' },
            });
            images = uploadResponse.data.names;
        }
    } catch (error) {
        console.error(`upload error: `, error);
    }
    if (uploadFormData || comment || seletedEmoji) {
      // const token = await executeRecaptcha('action');
      const { name, comment: parseComment } = commentName(comment);
      const emoji = seletedEmoji;
      // const ranking = [
      //   {
      //       brand: shoe.brand,
      //       id: id,
      //       itemId: 0,
      //       landedAt: landedAt,
      //       model: shoe.model,
      //       order_no: 1,
      //       selectedTime: dayjs().tz(TIMEZONE).format(FORMAT),
      //   }
      // ];
      // const reviewPayload = {
      //     ranking,
      //     fbUserID,
      //     fbEmail,
      //     recaptchaToken: token,
      //     feedbackSubmit: parseComment ? true : false,
      //     photoSubmit: uploadFormData ? true : false,
      //     emojiSubmit: emoji ? true : false,
      //     submittedAt: dayjs().tz(TIMEZONE).format(FORMAT),
      // };
      const emojiPayload = { emoji };
      const feedbacksPayload = {
        name,
        comment: parseComment,
        images: images?.join(','),
      }
      try {
        await axios.all([
          parseComment ? axios.post('/api/feedbacks', feedbacksPayload) : null,
          emoji ? axios.post(`/api/shoes/${id}/emojis`, emojiPayload) : null,
          // (parseComment) ? axios.post(`/api/reviews`, reviewPayload): null,
        ])
        .then(() => {
          fetchReviews(id);
          if (emoji) {
            setSeletedEmoji(null)
            fetchEmojis();
          }
        });
        if (window.fbq !== null) {
          window.fbq('track', 'Review Submitted');
        }
        setShowConfirmModal(false);
      } catch (error) {
        console.error(`submit feedback, review, emoji error: `, error);
      }
    }
};

  const descriptionSEO = (info) => {
    const { description, rank, total_shoes, total_votes } = info;
    if (description) return description;

    if (!rank) return `${total_votes} reviews. Join us and get the inside scoop now!`;

    return `${rank} out of ${total_shoes} shoes. ${total_votes} reviews. Join us and get the inside scoop now!`
  }

  return (
    <>
      <HelmetProvider context={{}}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            property="og:title"
            content={`${memoizedShoe.brand} ${memoizedShoe.model} reviews | Best Running Shoes 2023`} />
          <title>
            {
              memoizedShoe.title ?
              memoizedShoe.title :
                `${memoizedShoe.brand} ${memoizedShoe.model} reviews | Best Running Shoes 2023`
            }
          </title>
          <meta name="description" content={descriptionSEO(memoizedShoe)}/>
          <meta property="og:description" content={descriptionSEO(memoizedShoe)}/>
        </Helmet>
      </HelmetProvider>
      { !isLoading && 
        <div className='shoe'>
          <div className="container mb-3">
            <Info data={memoizedShoe} />
            <Rank data={memoizedShoe} />
            <Emoji emojis={emojis} seleted={seletedEmoji} onClick={(e) => onEmojiClick(e)} />
            <Vote data={memoizedShoe} onLike={onLike} onUnvote={onUnvote} />
            <InputReview onConfirm={onConfirmClick} />
            <Reviews reviews={reviews} />
            <MileageModal 
              show={showModal}
              userMileage={memoizedShoe.mileage}
              onConfirm={onMileageSubmit}
              onClose={() => setShowModal(false)} />
            <ConfirmModal 
              show={showConfirmModal}
              onConfirm={onConfirmClick}
              onClose={(e) => { setShowConfirmModal(false) }}/>
          </div>
        </div>
    
      }
    </>);
}

export default Shoe;
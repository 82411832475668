import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import HeaderLogo from '../assets/icon/header.svg';

import './Header.scss';

const Logo = () => {
  return (
    <img className="logo" src={HeaderLogo} alt="Logo" />
  );
};

function Header(props) {
  const navigate = useNavigate();

  return (
    <div className="py-2 header">
      <div className={classNames('d-flex justify-content-between align-items-center')}>
        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <Logo />
        </div>
        {
          // <div className="d-flex justify-content-end align-items-center w-50">
          //   <Link className="rank-button" to="/review">
          //     Rank 1-2-3
          //   </Link>
          // </div>
        }
        <a style={{ fontSize: '1rem' }} href="https://www.facebook.com/pibbiediary" target="_blank" rel="noreferrer">
          Discussion
        </a>
      </div>
    </div>
  )
}
export default Header;

import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import './MileageModal.scss';

const MILE_TO_KM = 1.60934;
const MAX_MILE = 999;
const MAX_KM = 1607;

function MileageModal(props) {

  const {
    show,
    userMileage,
    onClose = () => {},
    onConfirm = () => {},
  } = props;

  const [mile, setMile] = useState(0);
  const [km, setKm] = useState(0);

  useEffect(() => {
    let um = parseFloat(userMileage);
    if (isNaN(um)) um = 0;

    setMile(um);
  }, [userMileage]);

  const mileToKm = (mile) => {
    mile = parseFloat(mile);
    if (isNaN(mile)) return '-';

    return Math.round(mile * MILE_TO_KM * 100) / 100;
  };

  const kmToMile = (km) => {
    km = parseFloat(km);
    if (isNaN(km)) return '-';

    return Math.round(km / MILE_TO_KM * 100) / 100;
  };

  const onMileChange = (e) => {
    let mile = parseFloat(e.target.value);
    if (mile > MAX_MILE) mile = MAX_MILE;
    setMile(mile);
    setKm(mileToKm(mile));
  };

  const onKmChange = (e) => {
    let km = parseFloat(e.target.value);
    if (km > MAX_KM) km = MAX_KM;
    setKm(km);
    setMile(kmToMile(km));
  };

  return (
    <Modal className="mileage-modal modal" show={show} onHide={onClose} centered>
      <Modal.Body className="text-center my-2">
        <h4 className="mb-3">Average Mileage</h4>
        <div className="mb-2 d-flex align-items-end justify-content-center">
          <input type="number" value={mile}
            onChange={onMileChange} />
          <span>mile</span>
        </div>
        <div className="mb-2 d-flex align-items-end justify-content-center">
          <input type="number" value={km}
            onChange={onKmChange} />
          <span>km</span>
        </div>
        <div className="d-inline-flex flex-column mt-3">
          <Button className="mb-2"
            onClick={e => onConfirm(mile)}>Confirm</Button>
          <Button className="btn-secondary"
            onClick={onClose}>Cancel</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MileageModal;

import { useEffect, useState, memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import dayjs from 'dayjs';
import emoji from './emoji';
import './Votes.scss';

const Vote = memo(function Vote(props) {
  const { data, k } = props;
  const { name, comment, created_at } = data;
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(data.reviews?.slice(0, 3));
  }, [data])

  return (
    <div className="pb-3">
      <div>
        <div className="d-flex justify-content-between">
          <div className="fw-bold"><span>{emoji[k]}&nbsp;</span>{name}</div>
          <div>{dayjs(created_at).tz('Asia/Bangkok').format('DD/MM/YY')}</div>
        </div>
        <div className="fst-italic mt-1">{comment}</div>
      </div>
      <ol className="mt-1 small">
        { reviews && reviews.map((v, i) => (
            <li key={i}>{`${(i+1)}. ${v.brand || ''} ${v.model || ''}`}</li>
          )) }
      </ol>
    </div>
  )
});


function Votes() {
  const [data, setData] = useState([]);
  const [, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);

  const fetchData = () => {
    setLoading(true);
    axios.get(`/api/feedbacks?limit=${limit}&offset=${offset}`)
      .then(result => {
        const { data: newData } = result.data;
        setData(prevData => [...prevData, ...newData]);
        setOffset(prevOffset => prevOffset + limit);
        setHasMore(newData.length === limit); // Update 'hasMore' based on the fetched data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Initial data fetch

  return (
    <div className="votes mb-3">
      <div>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          }
        >
          {data.map((v, i) => (
            <Vote key={i} k={i % emoji.length} data={v} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default Votes;


import { Routes, Route } from 'react-router-dom';
import Ranking from './components/Ranking';
import Review from './components/Review';
import Thankyou from './components/Thankyou';
import Shoe from './components/Shoe';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import WithContext from './components/WithContext';
import Header from './components/Header';
import Footer from './components/Footer';

import './App.scss';

function App() {
  return (
    <div className="App">
      <HelmetProvider context={{}}>
        <Helmet>
        <meta
          property="og:title"
          content="Best Carbon Plate Running Shoes 2024 | Pibbie.com"
        />
        </Helmet>
      </HelmetProvider>
      <div className="container sticky">
        <Header/>
      </div>
        <Routes>
          <Route path="/" element={<Ranking />} />
          <Route path="/review" element={<Review />} />
          <Route path="/thankyou" element={<Thankyou/>} />
          <Route path="/shoes/:id/:name" element={<Shoe/>} />
        </Routes>
      <Footer />
    </div>
  );
}

export default WithContext(App);
